<template>
  <div id="imprint">
    <h1>About this page</h1>
    The following information (Impressum) is required under German law.
    <!--     
    Name des Anbieters Anschrift (kein Postfach)
Schnelle Kontaktmöglichkeit (E-Mail, Telefonnr. oder evtl. Kontaktformular)
Ggf. Firmenname, Rechtsform und Vertretungsberechtigter
Bei Eintrag in ein (z.B. Handels-, Vereins-, Genossenschafts-)Register: Registername, Ort, Registernr.
UStID oder Wirtschafts-Identifikationsnr.
Bei gesetzlich reglementierten Berufen: Gesetzl. Berufsbezeichnung, zuständige Kammer, Staat, von dem die Berufsbezeichnung verliehen wurde
Ggf. Aufsichtsbehörde
Bei journalistischen/redaktionellen Angeboten: Verantwortlicher im Sinne des Presserechts (V.i.S.d.P.) 

-->
    <h2>
      Online Dispute Resolution website of the EU Commission
    </h2>
    <p>
      In order for consumers and traders to resolve a dispute out-of-court, the
      European Commission developed the
      <a href="www.ec.europa.eu/consumers/odr"
        >Online Dispute Resolution Website</a
      >
    </p>
    <h2>
      Legal disclaimer
    </h2>

    <p>
      The contents of these pages were prepared with utmost care. Nonetheless,
      we cannot assume liability for the timeless accuracy and completeness of
      the information.
    </p>
    <p>
      Our website contains links to external websites. As the contents of these
      third-party websites are beyond our control, we cannot accept liability
      for them. Responsibility for the contents of the linked pages is always
      held by the provider or operator of the pages.
    </p>
    <h2>
      Data protection
    </h2>
    In general, when visiting the website of H. Le Duc , no personal data are
    saved. However, these data can be given on a voluntary basis. No data will
    be passed on to third parties without your consent. We point out that in
    regard to unsecured data transmission in the internet (e.g. via email),
    security cannot be guaranteed. Such data could possibIy be accessed by third
    parties.
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
#imprint {
  width: 30%;
  overflow: auto;
  margin: auto;
  text-align: justify;
}
@media (max-width: 720px) {
  #imprint {
    width: 90%;
    margin: auto;
    overflow: auto;
  }
}
</style>
